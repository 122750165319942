import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../components/layout";

import iconArrowRight from "../images/icons/icon-arrow-right.png";
import iconEye from "../images/icons/icon-eye.png";
import iconForum from "../images/icons/icon-forum.png";
import iconAccount from "../images/icons/icon-account-circle.png";
import imgFirst from "../images/new/img-new-first.png";
import imgBridge from "../images/new/img-body-first.png";
import imgWatch from "../images/new/img-body-second.png";
import imgHover from "../images/new/img-hover.png";

export default function News() {
  return (
    <>
      <Layout>
        <div className="new-page">
          <div className="container-wrap">
            <Row>
              <Col className="breadcrumb-page">
                <div className="breadcrumb-default">
                  <span>Trang chủ</span>
                </div>
                <div className="breadcrumb-icon">
                  <img src={iconArrowRight} alt="" />
                </div>
                <div className="breadcrumb-present">
                  <span>Tin tức</span>
                </div>
              </Col>
            </Row>
            <div className="new-page-wrap">
              <Row className="new-page-first">
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="new-first-left"
                >
                  <div className="new-first-img">
                    <img src={imgFirst} alt="" />
                  </div>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="new-firse-right"
                >
                  <div className="new-first-content">
                    <h2>The Glossary Of Telescopes</h2>
                    <p>
                      While it was just a TV show, that little speech at the
                      beginning of the original Star Trek show really did do a
                      good job of capturing our feelings about space.
                    </p>
                    <hr />
                    <div className="list-info">
                      <div className="list-info-first">
                        <div className="list-info-icon">
                          <img src={iconEye} alt="" />
                        </div>
                        <div className="list-info-content">
                          <span>275</span>
                        </div>
                      </div>
                      <div className="list-info-second">
                        <div className="list-info-icon">
                          <img src={iconForum} alt="" />
                        </div>
                        <div className="list-info-content">
                          <span>12</span>
                        </div>
                      </div>
                      <div className="list-info-last">
                        <div className="list-info-icon">
                          <img src={iconAccount} alt="" />
                        </div>
                        <div className="list-info-content">
                          <span>Arthur Young</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="new-page-second">
                <Col
                  xl={4}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="item-first"
                >
                  <div className="item-img">
                    <img src={imgBridge} alt="" />
                  </div>
                  <div className="item-content">
                    <div className="item-title">
                      <h4>5 Ways To Make Your Bbq A Memorable Family Event</h4>
                    </div>
                    <div className="item-desc">
                      <p>
                        Buying the right telescope to take your love of
                        astronomy to the next level is a big next step in the
                        development
                      </p>
                    </div>
                    <div className="item-info">
                      <div className="item-info-1">
                        <img src={iconEye} alt="" />
                        <span>275</span>
                      </div>
                      <div className="item-info-2">
                        <img src={iconForum} alt="" />
                        <span>12</span>
                      </div>
                    </div>
                  </div>
                  <div className="img-hover">
                    <div className="img-hover-item">
                      <img src={imgHover} alt="" />
                      <h4>The Best Writing Any</h4>
                      <button>
                        <span>KHÁM PHÁ NGAY</span>
                      </button>
                    </div>
                  </div>
                </Col>
                <Col
                  xl={4}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="item-second"
                >
                  <div className="item-img">
                    <img src={imgWatch} alt="" />
                  </div>
                  <div className="item-content">
                    <div className="item-title">
                      <h4>5 Ways To Make Your Bbq A Memorable Family Event</h4>
                    </div>
                    <div className="item-desc">
                      <p>
                        Buying the right telescope to take your love of
                        astronomy to the next level is a big next step in the
                        development
                      </p>
                    </div>
                    <div className="item-info">
                      <div className="item-info-1">
                        <img src={iconEye} alt="" />
                        <span>275</span>
                      </div>
                      <div className="item-info-2">
                        <img src={iconForum} alt="" />
                        <span>12</span>
                      </div>
                    </div>
                  </div>
                  <div className="img-hover">
                    <div className="img-hover-item">
                      <img src={imgHover} alt="" />
                      <h4>The Best Writing Any</h4>
                      <button>
                        <span>KHÁM PHÁ NGAY</span>
                      </button>
                    </div>
                  </div>
                </Col>
                <Col
                  xl={4}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="item-last"
                >
                  <div className="item-img">
                    <img src={imgWatch} alt="" />
                  </div>
                  <div className="item-content">
                    <div className="item-title">
                      <h4>5 Ways To Make Your Bbq A Memorable Family Event</h4>
                    </div>
                    <div className="item-desc">
                      <p>
                        Buying the right telescope to take your love of
                        astronomy to the next level is a big next step in the
                        development
                      </p>
                    </div>
                    <div className="item-info">
                      <div className="item-info-1">
                        <img src={iconEye} alt="" />
                        <span>275</span>
                      </div>
                      <div className="item-info-2">
                        <img src={iconForum} alt="" />
                        <span>12</span>
                      </div>
                    </div>
                  </div>
                  <div className="img-hover">
                    <div className="img-hover-item">
                      <img src={imgHover} alt="" />
                      <h4>The Best Writing Any</h4>
                      <button>
                        <span>KHÁM PHÁ NGAY</span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="new-page-last">
                <Row className="page-last-header">
                  <Col>
                    <h2>Most Popular Articles</h2>
                  </Col>
                </Row>
                <Row className="page-last-list">
                  <Col
                    xl={3}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="page-last-item-1"
                  >
                    <div className="item-1-new">
                      <span>NEW</span>
                    </div>
                    <div className="item-1-title">
                      <h5>The Universe Through A Child S Eyes</h5>
                    </div>
                    <div className="item-1-desc">
                      <p>
                        Buying the right telescope to take your love of
                        astronomy to the next level is a big next step
                      </p>
                    </div>
                    <hr />
                    <div className="item-1-info">
                      <div className="item-info-first">
                        <img src={iconEye} alt="" />
                        <span>275</span>
                      </div>
                      <div className="item-info-second">
                        <img src={iconForum} alt="" />
                        <span>12</span>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xl={3}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="page-last-item-2"
                  >
                    <div className="item-2-new">
                      <span>NEW</span>
                    </div>
                    <div className="item-2-title">
                      <h5>The Universe Through A Child S Eyes</h5>
                    </div>
                    <div className="item-2-desc">
                      <p>
                        Buying the right telescope to take your love of
                        astronomy to the next level is a big next step
                      </p>
                    </div>
                    <hr />
                    <div className="item-2-info">
                      <div className="item-info-first">
                        <img src={iconEye} alt="" />
                        <span>275</span>
                      </div>
                      <div className="item-info-second">
                        <img src={iconForum} alt="" />
                        <span>12</span>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xl={3}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="page-last-item-3"
                  >
                    <div className="item-3-new">
                      <span>NEW</span>
                    </div>
                    <div className="item-3-title">
                      <h5>The Universe Through A Child S Eyes</h5>
                    </div>
                    <div className="item-3-desc">
                      <p>
                        Buying the right telescope to take your love of
                        astronomy to the next level is a big next step
                      </p>
                    </div>
                    <hr />
                    <div className="item-3-info">
                      <div className="item-info-first">
                        <img src={iconEye} alt="" />
                        <span>275</span>
                      </div>
                      <div className="item-info-second">
                        <img src={iconForum} alt="" />
                        <span>12</span>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xl={3}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="page-last-item-4"
                  >
                    <div className="item-4-new">
                      <span>NEW</span>
                    </div>
                    <div className="item-4-title">
                      <h5>The Universe Through A Child S Eyes</h5>
                    </div>
                    <div className="item-4-desc">
                      <p>
                        Buying the right telescope to take your love of
                        astronomy to the next level is a big next step
                      </p>
                    </div>
                    <hr />
                    <div className="item-4-info">
                      <div className="item-info-first">
                        <img src={iconEye} alt="" />
                        <span>275</span>
                      </div>
                      <div className="item-info-second">
                        <img src={iconForum} alt="" />
                        <span>12</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
